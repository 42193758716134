html, body {
  height: 100%;
  width: 100%;
}

.App {
  background-color: #F8f8f8;
  position: relative;
  height: 100%;
}

.EpisodeContainer {
  padding: 20px;
}

.header {
  width: 100%;
  background-color: #fff;
  text-align: center;
}

.footer {
  width: 100%;
  background-color: #fff;
  text-align: center;
  padding: 150px;
}

.youtube {
  max-width: 100%; 
  height: auto;
}

.topimage {
  max-width: 100%; 
  height: auto;
}

.left {
  background-color: #0068FF;
  height: 100%;
  text-align: center;
}

.td {
  font-size: 40pt;
  font-weight: bolder;
  color: #666;
}

.tdc {
  font-size: 40pt;
  font-weight: bold;
  color: #0068FF;
}

.wt {
  font-size: 40pt;
  color: #bbb;
}

.right {
  font-size: 12pt;
}